@use 'abstract/variables' as *;

// Flex
.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: self-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

// paddings
$padding-values: 0, 4, 8, 10, 12, 16, 24, 32, 40, 48, 56;
$directions: t, r, b, l, y, x;

@each $value in $padding-values {
  .p-#{$value} {
    padding: #{$value}px !important;
  }

  @each $direction in $directions {
    .p#{$direction}-#{$value} {
      @if $direction == t {
        padding-top: #{$value}px !important;
      } @else if $direction == r {
        padding-right: #{$value}px !important;
      } @else if $direction == b {
        padding-bottom: #{$value}px !important;
      } @else if $direction == l {
        padding-left: #{$value}px !important;
      } @else if $direction == y {
        padding-top: #{$value}px !important;
        padding-bottom: #{$value}px !important;
      } @else if $direction == x {
        padding-left: #{$value}px !important;
        padding-right: #{$value}px !important;
      }
    }
  }
}

// margins
$margins-values: 0, 4, 8, 10, 12, 16, 24, 32, 40, 48, 56;
$directions: t, r, b, l;

@each $value in $margins-values {
  .m-#{$value} {
    margin: #{$value}px !important;
  }
  @each $direction in $directions {
    .m#{$direction}-#{$value} {
      @if $direction == t {
        margin-top: #{$value}px !important;
      } @else if $direction == r {
        margin-right: #{$value}px !important;
      } @else if $direction == b {
        margin-bottom: #{$value}px !important;
      } @else if $direction == l {
        margin-left: #{$value}px !important;
      }
    }
  }
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}
.m-auto {
  margin: 0 auto !important;
}
// gaps
$gaps: 4, 8, 16, 24, 32;
@each $value in $margins-values {
  .gap-#{$value} {
    gap: #{$value}px;
  }
}

// height
.f-height {
  height: 100%;
}

.f-m-height {
  min-height: 100%;
}

// width
.f-width {
  width: 100%;
}
$width-values: 20, 25, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95;
@each $value in $width-values {
  .width-#{$value} {
    width: #{$value}#{'%'} !important;
  }
}

// pointers

.cursor-pointer {
  cursor: pointer;
}

// colors
.cl-primary {
  color: var(--primary-color);
}

.cl-error {
  color: var(--error-color);
}

.cl-on-surface {
  color: var(--on-surface-color);
}

.cl-on-surface-variant {
  color: var(--on-surface-variant-color);
}

.cl-on-background {
  color: var(--on-background-color);
}

// texts

.t-align-r {
  text-align: right !important;
}

.t-align-l {
  text-align: left !important;
}

.t-align-c {
  text-align: center !important;
}

.t-align-j {
  text-align: justify;
}
.text-capitalize {
  text-transform: capitalize !important;
}
// links
.link {
  text-decoration: underline;
  color: #235b82;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}

.ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//border
.border-bottom-default {
  border-bottom: 1px solid var(--outline-color);
}
.border-top-default {
  border-top: 1px solid var(--outline-color);
}

.text-color-light {
  color: var(--on-surface-variant-color);
}
.border-default {
  border: 1px solid var(--outline-color);
}
.border-radius-10 {
  border-radius: 10px;
}
.divider {
  display: flex;
  border-bottom: 1px solid var(--outline-color);
}

//font-size
$font-values: 12, 14, 16, 18, 20, 22, 24, 26, 28, 30;
@each $value in $font-values {
  .font-size-#{$value} {
    font-size: #{$value}px !important;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.no-select {
  user-select: none;
  -webkit-user-select: none; /* For Safari */
  -ms-user-select: none; /* For Internet Explorer */
  -moz-user-select: none; /* For Firefox */
}
