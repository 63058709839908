.ant-segmented {
  height: 38px;
  padding: 2px;
}
.ant-segmented-group {
  height: 34px;
}

.ant-segmented-item,
.ant-segmented-thumb-motion {
  padding-left: 20px;
  padding-right: 28px;
}

.ant-segmented-thumb-motion,
.ant-segmented-item-selected {
  //outline: 1px solid #0f8359;
}

.ant-segmented-item-label {
  min-height: 34px;
  line-height: 34px !important;
}

.form-segmented {
  border-radius: 28px;
  background-color: #e3eaee;
  &:hover {
    background-color: #e3eaee !important;
  }
  .ant-segmented-item-selected {
    border-radius: 28px;
    background-color: #eaf2f5;
    color: #2c3542;
  }
  .ant-segmented-thumb {
    border-radius: 28px;
    background-color: #eaf2f5;
  }
}
