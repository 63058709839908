.ant-progress-line {
  font-size: 0.75rem;

  &> div {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 0;
    align-items: center;

    .ant-progress-outer {
      margin-right: 0;
      padding-right: 0;
    }
  }
}

.ant-progress-outer {
  width: 100%;
}

.ant-progress-text {
  width: initial;
}

