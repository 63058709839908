.avatar-uploader {
  .ant-upload-select-picture-card {
    border: 1px solid #d3eaf3;
    background-color: #eefbff;
  }
  .ant-upload {
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    img {
      height: 100%;
    }
  }
  .ant-upload-text {
    position: absolute;
    inset: 0;
  }
  .add-icon {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
