@use 'abstract/mixins' as *;

//////////// new design system ////////////
.title-3xl {
  @include title-3xl;
}

.title-2xl {
  @include title-2xl;
}

.title-xl {
  @include title-xl;
}

.title-l {
  @include title-l;
}

.title-m {
  @include title-m;
}

.title-b {
  @include title-b;
}

.body-l {
  @include body-l;
}

.body-b {
  @include body-b;
}

.body-m {
  @include body-m;
}

.body-c {
  @include body-c;
}

.label-l {
  @include label-l;
}

.label-m {
  @include label-m;
}

.label-s {
  @include label-s;
}

.fw-400 {
  @include font-weight(400);
}

.fw-500 {
  @include font-weight(500);
}

.fw-600 {
  @include font-weight(600);
}

.fw-700 {
  @include font-weight(700);
}
