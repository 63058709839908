.ant-spin-nested-loading > div > .ant-spin {
  max-height: unset;
}

.ant-table {
  .ant-table-container {
    border-radius: 0 !important;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 0 !important;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 0 !important;
  }
  .ant-table-thead {
    .ant-table-cell::before {
      content: none !important;
    }
  }
}

.ant-table-row .expanded {
  border-bottom: 0;
}

.ant-table-pagination {
  display: none;
}

.custom-expanded-table {
  .ant-table-expanded-row {
    .ant-table {
      margin: -16px 32px -16px 48px !important;
    }
    > td {
      background-color: #eefbff !important;
    }
    .ant-table-tbody {
      tr {
        position: relative;
      }
      tr:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -32px;
        width: 1px;
        background-color: var(--outline-variant-color);
      }
      tr:last-child:before {
        bottom: 24px;
      }
      tr:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: -25px;
        width: 1px;
        background-color: var(--outline-variant-color);
      }
      tr:last-child:after {
        bottom: 24px;
      }
      tr:last-child td:first-child:before {
        content: '';
        position: absolute;
        width: 20px;
        bottom: 24px;
        left: -32px;
        height: 1px;
        background-color: var(--outline-variant-color);
      }
      tr:last-child td:last-child:after {
        content: '';
        position: absolute;
        width: 20px;
        bottom: 24px;
        right: -25px;
        height: 1px;
        background-color: var(--outline-variant-color);
      }
    }
  }
}

.cascaded-partners {
  .ant-table-expanded-row .ant-table {
    margin: -8px -8px -8px -8px !important;
  }
}

.facility-table {
  min-width: 700px;

  .ant-table-expanded-row {
    > td {
      background-color: #eefbff !important;
    }
  }
}

//////// new design //////

.ant-table-thead > tr > th,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 10px 16px;
}

.ant-table-tbody > tr > td {
  background: #eefbff;
  border-bottom: 1px solid #def0f8;
}

.ant-table-tbody > tr > td:hover {
  background: #f6fdff;
}

.ant-table-thead > tr > th {
  background: #def0f8;
}
