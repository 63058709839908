@use 'abstract/variables' as *;

:root {
  --primary-color: #{$primary-color};
  --on-primary-color: #{$on-primary-color};
  --primary-container-color: #{$primary-container-color};
  --on-primary-container-color: #{$on-primary-container-color};

  --secondary-color: #{$secondary-color};
  --on-secondary-color: #{$on-secondary-color};
  --secondary-container-color: #{$secondary-container-color};
  --on-secondary-container-color: #{$on-secondary-container-color};

  --error-color: #{$error-color};
  --on-error-color: #{$on-error-color};

  --error-container-color: #{$error-container-color};
  --on-error-container-color: #{$on-error-container-color};

  //--background-color: #{$background-color};
  --on-background-color: #{$on-background-color};

  --surface-color: #{$surface-color};
  --on-surface-color: #{$on-surface-color};

  --surface-variant-color: #{$surface-variant-color};
  --on-surface-variant-color: #{$on-surface-variant-color};

  --outline-color: #{$outline-color};
  --outline-variant-color: #{$outline-variant-color};

  --surface-tint-5-color: #{$surface-tint-5-color};
  --surface-tint-8-color: #{$surface-tint-8-color};
  --surface-tint-11-color: #{$surface-tint-11-color};
  --surface-tint-12-color: #{$surface-tint-12-color};
  --surface-tint-14-color: #{$surface-tint-14-color};

  /// Redesign
  --navbar-border-bg: #{$navbar-border-bg};
  --background-color: #{$background-color};

  &.dark-theme {
    --primary-color: #{$primary-color};
  }
}

html,
body {
  font-family: 'Inter', sans-serif;
  background-color: var(--background-color);
}

.light_bg {
  body {
    background-color: #eefbff;
  }
}

.dark_bg {
  body {
    background-color: #20272cf0;
  }
}
