@use 'abstract/variables' as *;
@use 'abstract/functions' as *;

.topic-label {
  color: #7794a8;
}

.subtopic-tabs {
  display: flex;
  justify-content: space-between;
  padding: 10px 12px;
  gap: 16px;
}

.subtopic-tabs-selector {
  min-width: 250px;
}

.actions-overview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  gap: 8px;
  border-top: 1px solid #def0f8;
  border-bottom: 1px solid #def0f8;

  & > span {
    margin-right: auto;
  }

  .action-buttons {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;

    &:not(:empty) {
      &::after {
        content: '';
        width: 1px;
        margin: 4px 0;
        align-self: stretch;
        background-image: linear-gradient(#d3eaf3 50%, transparent 33%);
        background-position: right;
        background-size: 1px 5px;
        background-repeat: repeat-y;
      }
    }
  }
}

.activity-container {
  min-height: 300px;
}
