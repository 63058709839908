@use 'abstract/mixins' as *;

.ant-breadcrumb {
  @include body-m;
  display: inline-flex;
  color: var(--on-surface-variant-color);
  nz-breadcrumb-item {
    display: inline-flex;
  }
  nz-breadcrumb-item:last-child {
    color: var(--on-background-color);
    pointer-events: none;
    cursor: initial;
  }
  nz-breadcrumb-item:last-child .ant-breadcrumb-separator,
  nz-breadcrumb-item:first-child .ant-breadcrumb-separator {
    display: none;
  }
}

.ant-breadcrumb-separator {
  margin: 0 4px;
}

ie-breadcrumbs {
  display: flex;
}
