.ant-drawer-footer {
  border-top: 1px solid #d2dfe6;
  padding: 20px 24px;
}

.ant-drawer-header {
  border-bottom: 1px solid #d2dfe6;
}

.ant-drawer-title {
  color: #596971;
}

.ant-drawer-header-title {
  flex-direction: row-reverse;
}

.ant-drawer-close {
  margin-right: unset;
  margin-left: 16px;
}

.ant-drawer-right > .ant-drawer-content-wrapper {
  top: 14px;
  right: 0;
  bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
}

.ant-drawer-open {
  &.ant-drawer-right > .ant-drawer-content-wrapper {
    top: 14px;
    right: 20px;
    bottom: 20px;
    border-radius: 8px;
    overflow: hidden;
  }
}

.ant-drawer-wrapper-body {
  background-color: #eaf2f5;
}

.no-paddings {
  .ant-drawer-body {
    padding: 0;
  }
}
