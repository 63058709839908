.ant-input-lg {
  font-size: 14px;
  line-height: 25px;
}

.ant-input-prefix {
  margin-right: 8px;
}

.ant-input-affix-wrapper-sm {
  padding: 6px 11px;
}

.ant-date-picker-input {
  width: 80px;
}

app-date-picker-cell {
  .ant-picker {
    padding: 0;
  }

  .ant-picker-input > input {
    font-size: 0.75rem;
  }
}
