@use 'abstract/variables' as *;
@use 'abstract/functions' as *;

.company-form-container {
  border-top: 1px solid $neutral-90;

  form.form {
    $grid-column-count: 3;
    $grid-item--min-width: pxToRem(250);
    $grid-row-gap: $sm-padding;
    $grid-column-gap: pxToRem(100);

    $gap-count: calc($grid-column-count - 1);
    $total-gap-width: calc($gap-count * $grid-column-gap);
    $grid-item--max-width: calc((100% - $total-gap-width) / $grid-column-count);

    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(max($grid-item--min-width, $grid-item--max-width), 1fr)
    );
    grid-row-gap: $grid-row-gap;
    grid-column-gap: $grid-column-gap;
  }
}

.org-form-container {
  .form {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    gap: 1%;
    > div {
      flex: 1 1 49%;
      &:last-child {
        width: 49.5%;
        flex: unset;
      }
    }
  }
}

.org-form-container-one-col {
  width: 50%;
}
