@use 'functions' as *;

@mixin card-box-shadow() {
  box-shadow: 0 1px 3px 0 #00000026;
}

// Mixin for font styles
@mixin font-style($font-size, $line-height, $letter-spacing: null) {
  font-size: pxToRem($font-size);
  line-height: pxToRem($line-height);
  @if $letter-spacing != null {
    letter-spacing: pxToRem($letter-spacing);
  }
}

// Mixin for font weights
@mixin font-weight($weight) {
  font-weight: $weight;
}

/////// new mixins for display styles
@mixin title-3xl {
  @include font-style(48, 40);
}

@mixin title-2xl {
  @include font-style(40, 48);
}

@mixin title-xl {
  @include font-style(32, 48);
}

@mixin title-l {
  @include font-style(24, 32);
}

@mixin title-m {
  @include font-style(18, 24);
}

@mixin title-b {
  @include font-style(16, 24);
}

@mixin body-l {
  @include font-style(18, 24);
}

@mixin body-b {
  @include font-style(16, 24);
}

@mixin body-m {
  @include font-style(14, 24);
}

@mixin body-c {
  @include font-style(12, 16);
}

@mixin label-l {
  @include font-style(14, 24);
}

@mixin label-m {
  @include font-style(14, 20);
}

@mixin label-s {
  @include font-style(12, 16);
}

@mixin badge-after($right-position) {
  content: '';
  position: absolute;
  background-color: #235b82;
  border-radius: 100%;
  width: 6px;
  height: 6px;
  right: $right-position;
}
