@use 'table';
@use 'input';
@use 'breadcrumbs';
@use 'segmented';
@use 'drawer';
@use 'select';
@use 'divider';
@use 'popover';
@use 'modal';
@use 'progress';
@use 'tab';
@use 'uploader';
@use 'notifications';
@use 'steps';

.ant-picker-suffix,
.ant-select-arrow,
.ant-select-clear {
  color: #9eb4c2;
}
