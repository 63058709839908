// colors
$primary-0: #000000;
$primary-10: #18392e;
$primary-20: #18543f;
$primary-30: #156f4f;
$primary-40: #0f8359;
$primary-50: #1faa78;
$primary-60: #3bd49d;
$primary-70: #59edb7;
$primary-80: #77facb;
$primary-90: #cfffed;
$primary-100: #ffffff;

$secondary-0: #000000;
$secondary-10: #0b2051;
$secondary-20: #103385;
$secondary-30: #1945aa;
$secondary-40: #1f55d2;
$secondary-50: #336aeb;
$secondary-60: #618aec;
$secondary-70: #81a2ef;
$secondary-80: #a4befd;
$secondary-90: #c9d9ff;
$secondary-100: #ffffff;

$error-0: #000000;
$error-10: #410e0b;
$error-20: #601410;
$error-30: #8c1d18;
$error-40: #b3261e;
$error-50: #dc362e;
$error-60: #e46962;
$error-70: #ec928e;
$error-80: #f2b8b5;
$error-90: #f9dedc;
$error-100: #ffffff;

$neutral-0: #000000;
$neutral-10: #151618;
$neutral-20: #23252a;
$neutral-30: #323640;
$neutral-40: #474c58;
$neutral-50: #525866;
$neutral-60: #757c8e;
$neutral-70: #a2a9ba;
$neutral-80: #c2c8d6;
$neutral-90: #e3e7f1;
$neutral-99: #f8f9fa;
$neutral-100: #ffffff;

$primary-color: $primary-40;
$on-primary-color: $primary-100;

$primary-container-color: #3bd49d33;
$on-primary-container-color: $primary-60;

$secondary-color: $secondary-50;
$on-secondary-color: #336aeb33;

$secondary-container-color: #336aeb33;
$on-secondary-container-color: $secondary-50;

$error-color: $error-50;
$on-error-color: $error-100;

$error-container-color: #dc362e33;
$on-error-container-color: $error-60;

$background-color: $neutral-100;
$on-background-color: $neutral-20;

$surface-color: #f8f9fa;
$on-surface-color: $neutral-50;

$surface-variant-color: $neutral-90;
$on-surface-variant-color: $neutral-60;

$outline-color: $neutral-90;
$outline-variant-color: $neutral-80;

$surface-tint-5-color: #0f83590c;
$surface-tint-8-color: #0f835914;
$surface-tint-11-color: #0f83591c;
$surface-tint-12-color: #0f83591e;
$surface-tint-14-color: #0f835923;

// Redesign
$navbar-border-bg: #252e34;
$background-color: #e2ecef;

//
$sm-radius: 4px;
$md-radius: 8px;
$lg-radius: 16px;

$sm-padding: 8px;
$md-padding: 12px;
