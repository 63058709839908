.auto-scroll {
  nz-select-top-control {
    flex-wrap: nowrap !important;
    overflow: auto;
  }
}

.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  left: 11px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 0 11px;
}

.ant-select .ant-input-affix-wrapper {
  padding: 6px 11px;
}

.filter-select-width {
  width: 250px;

  .ant-select-selection-item-content {
    max-width: 70px;
  }
}

.filter-select-width-md {
  width: 188px;

  .ant-select-selection-item-content {
    max-width: 60px;
  }
}

.filter-select-width-xs {
  width: 140px;
}

.filter-select-width-sm {
  width: 180px;
}

.max-h-150 {
  nz-select-top-control {
    max-height: 150px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
      background: #737070;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555353;
    }
  }
}

.phone-code-search input {
  border: 0;
  border-bottom: 1px solid #d3eaf3;
  border-radius: 8px 8px 0 0;
  padding: 10px;
}

.phone-code-search input:focus {
  border-color: #d3eaf3;
  box-shadow: unset;
  border-right-width: unset;
  outline: 0;
}

.ant-select-clear {
  right: 38px;

  &:before {
    content: '';
    position: absolute;
    left: 19px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 150%;
    background-color: #d9d9d9;
    z-index: 1;
  }

  &:after {
    content: url('assets/icons/global/close.png');
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #ffffff;
  }

  .anticon-close-circle {
    visibility: hidden;
  }
}

.ng-dirty {
  .ant-select-clear {
    &:hover {
      opacity: 1;
    }
  }

  .ant-select-close-icon {
    opacity: 0;
  }
}

.ng-pristine {
  .ant-select-clear {
    opacity: 0;
  }
}

.ant-select-open {
  .ant-select-arrow .anticon-down {
    transition-duration: 500ms;
    transform: rotate(180deg);
  }
}
