@use 'abstract/mixins' as *;

.no-paddings {
  .ant-modal-body {
    padding: 0;
  }
}

.notification-modal {
  .ant-modal-header {
    border-bottom: unset;
  }

  .ant-modal-title {
    @include title-l;
    color: #2c3542;
    font-weight: 700;
  }
}

.preview-model {
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-content {
    background: #e2ecef;
  }
  .ant-modal-footer {
    background-color: #f6feff;
    padding: 12px 0 18px;
  }
}
